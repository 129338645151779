export const APPCONFIG: any = {
  sessionCookieStorageKey: 'cookie-session-data',
  serverConfig: {
    grant_type: 'password',
    client_id: 2,
    client_secret: 'xxp3tsXSuDXvsElpfMdi6OR6gsLL2PsDAHozZBX4'
  },
 
  // Dev endpoints brazil  
  /*
  endpoints: {
    login: 'https://staging-api.prodooh.com/oauth/token',
    services: 'https://staging-api.prodooh.com',
    users_imgs: 'https://staging-api.prodooh.com/img/users',
    screens_imgs: 'https://api.prodooh.com/img/screens',
    companies_imgs: 'https://staging-api.prodooh.com/img/companies',
    user_upload_img: 'https://staging-api.prodooh.com/users/upload/image',
    screen_upload_img: 'https://staging-api.prodooh.com/screens/image/upload',
    report_upload_img: 'https://staging-api.prodooh.com/campaigns/report/image',
    company_upload_img: 'https://staging-api.prodooh.com/companies/upload/image',
    creatives_imgs: 'https://staging-api.prodooh.com/img/screen_creatives',
    pdfs_temporary: 'https://staging-api.prodooh.com/pdfs/temporary',
    excels_temporary: 'https://staging-api.prodooh.com/excels/temporary',
    upload_files_particular_points: 'https://staging-api.prodooh.com/particular-points-interest/upload',
    files_particular_points: 'https://staging-api.prodooh.com/files/temporary',
    base_files: 'https://staging-api.prodooh.com/files',
    kmlKmzUpload: 'https://staging-api.prodooh.com/mapping-file/upload',
    kmlKmzFiles: 'https://staging-api.prodooh.com/files/kml_kmz',
    uploadImpactsFiles: 'https://staging-api.prodooh.com/impacts/upload',
    uploadImpactsReportFiles: 'https://staging-api.prodooh.com/impacts-report/upload',
    uploudFiles: 'https://staging-api.prodooh.com/file-manager/upload',
    photographicWitnesses: 'https://staging-api.prodooh.com/campaigns/witnesses/upload/image',
    witnessesAdManager: 'https://staging-api.prodooh.com/campaigns/witnesses/upload/reports',
    witnesses_imgs: 'https://staging-api.prodooh.com/img/witnesses',
    temporary_witnesses_zip: 'https://staging-api.prodooh.com/files/temporary/zips',
    download_witnesses_imgs: 'https://staging-api.prodooh.com/public/download/img',
    media_content_upload_img: 'https://staging-api.prodooh.com/media-content/upload/image',
    media_contents_imgs: 'https://staging-api.prodooh.com/img/media_contents',
    temporary_files: 'https://staging-api.prodooh.com/files/temporary',
    img_reports: 'https://staging-api.prodooh.com',
    upload_report_ad_circuit: 'https://staging-api.prodooh.com/campaigns/ads/report/upload',
    company_upload_img_portada: 'https://staging-api.prodooh.com/companies/upload/image-portada',
    pdf_portada: 'https://staging-api.prodooh.com/img/portadas',
    country_promotions: 'https://staging-api.prodooh.com/countries/upload/promotions'
    pdf_promotions: 'https://staging-api.prodooh.com/pdfs/promotions/',
    screen_template: 'https://staging-api.prodooh.com/screen-template/upload-file'
  } /

  endpoints: {
    login: 'http://prodooh-service-old.test/oauth/token',
    services: 'http://prodooh-service-old.test',
    users_imgs: 'http://prodooh-service-old.test/img/users',
    screens_imgs: 'https://api.prodooh.com/img/screens',
    companies_imgs: 'http://prodooh-service-old.test/img/companies',
    user_upload_img: 'http://prodooh-service-old.test/users/upload/image',
    screen_upload_img: 'http://prodooh-service-old.test/screens/image/upload',
    report_upload_img: 'http://prodooh-service-old.test/campaigns/report/image',
    company_upload_img: 'http://prodooh-service-old.test/companies/upload/image',
    creatives_imgs: 'http://prodooh-service-old.test/img/screen_creatives',
    pdfs_temporary: 'http://prodooh-service-old.test/pdfs/temporary',
    excels_temporary: 'http://prodooh-service-old.test/excels/temporary',
    upload_files_particular_points: 'http://prodooh-service-old.test/particular-points-interest/upload',
    files_particular_points: 'http://prodooh-service-old.test/files/temporary',
    base_files: 'http://prodooh-service-old.test/files',
    kmlKmzUpload: 'http://prodooh-service-old.test/mapping-file/upload',
    kmlKmzFiles: 'http://prodooh-service-old.test/files/kml_kmz',
    uploadImpactsFiles: 'http://prodooh-service-old.test/impacts/upload',
    uploadImpactsReportFiles: 'http://prodooh-service-old.test/impacts-report/upload',
    uploudFiles: 'http://prodooh-service-old.test/file-manager/upload',
    photographicWitnesses: 'http://prodooh-service-old.test/campaigns/witnesses/upload/image',
    witnessesAdManager: 'http://prodooh-service-old.test/campaigns/witnesses/upload/reports',
    witnesses_imgs: 'http://prodooh-service-old.test/img/witnesses',
    temporary_witnesses_zip: 'http://prodooh-service-old.test/files/temporary/zips',
    download_witnesses_imgs: 'http://prodooh-service-old.test/public/download/img',
    media_content_upload_img: 'http://prodooh-service-old.test/media-content/upload/image',
    media_contents_imgs: 'http://prodooh-service-old.test/img/media_contents',
    temporary_files: 'http://prodooh-service-old.test/files/temporary',
    img_reports: 'http://prodooh-service-old.test',
    upload_report_ad_circuit: 'http://prodooh-service-old.test/campaigns/ads/report/upload',
    company_upload_img_portada: 'http://prodooh-service-old.test/companies/upload/image-portada',
    pdf_portada: 'http://prodooh-service-old.test/img/portadas',
    country_promotions: 'http://prodooh-service-old.test/countries/upload/promotions',
    pdf_promotions: 'http://prodooh-service-old.test/pdfs/promotions/',
    screen_template: 'http://prodooh-service-old.test/screen-template/upload-file'
  } */
  
  endpoints: {
    login: 'https://api.prodooh.com/oauth/token',
    services: 'https://api.prodooh.com',
    users_imgs: 'https://api.prodooh.com/img/users',
    screens_imgs: 'https://api.prodooh.com/img/screens',
    companies_imgs: 'https://api.prodooh.com/img/companies',
    user_upload_img: 'https://api.prodooh.com/users/upload/image',
    screen_upload_img: 'https://api.prodooh.com/screens/image/upload',
    report_upload_img: 'https://api.prodooh.com/campaigns/report/image',
    company_upload_img: 'https://api.prodooh.com/companies/upload/image',
    creatives_imgs: 'https://api.prodooh.com/img/screen_creatives',
    pdfs_temporary: 'https://api.prodooh.com/pdfs/temporary',
    excels_temporary: 'https://api.prodooh.com/excels/temporary',
    upload_files_particular_points: 'https://api.prodooh.com/particular-points-interest/upload',
    files_particular_points: 'https://api.prodooh.com/files/temporary',
    base_files: 'https://api.prodooh.com/files',
    kmlKmzUpload: 'https://api.prodooh.com/mapping-file/upload',
    kmlKmzFiles: 'https://api.prodooh.com/files/kml_kmz',
    uploadImpactsFiles: 'https://api.prodooh.com/impacts/upload',
    uploadImpactsReportFiles: 'https://api.prodooh.com/impacts-report/upload',
    uploudFiles: 'https://api.prodooh.com/file-manager/upload',
    photographicWitnesses: 'https://api.prodooh.com/campaigns/witnesses/upload/image',
    witnessesAdManager: 'https://api.prodooh.com/campaigns/witnesses/upload/reports',
    witnesses_imgs: 'https://api.prodooh.com/img/witnesses',
    temporary_witnesses_zip: 'https://api.prodooh.com/files/temporary/zips',
    download_witnesses_imgs: 'https://api.prodooh.com/public/download/img',
    temporary_files: 'https://api.prodooh.com/files/temporary',
    img_reports: 'https://api.prodooh.com',
    upload_report_ad_circuit: 'https://api.prodooh.com/campaigns/ads/report/upload',
    company_upload_img_portada: 'https://api.prodooh.com/companies/upload/image-portada',
    pdf_portada: 'https://api.prodooh.com/img/portadas',
    country_promotions: 'https://api.prodooh.com/countries/upload/promotions',
    pdf_promotions: 'https://api.prodooh.com/pdfs/promotions/',
    screen_template: 'https://api.prodooh.com/screen-template/upload-file'
  }
  
  /*
  endpoints: {
    login: 'https://api-prodooh.oohgroup.com/oauth/token',
    services: 'https://api-prodooh.oohgroup.com',
    users_imgs: 'https://api-prodooh.oohgroup.com/img/users',
    screens_imgs: 'https://api-prodooh.oohgroup.com/img/screens',
    companies_imgs: 'https://api-prodooh.oohgroup.com/img/companies',
    user_upload_img: 'https://api-prodooh.oohgroup.com/users/upload/image',
    screen_upload_img: 'https://api-prodooh.oohgroup.com/screens/image/upload',
    report_upload_img: 'https://api-prodooh.oohgroup.com/campaigns/report/image',
    company_upload_img: 'https://api-prodooh.oohgroup.com/companies/upload/image',
    pdfs_temporary: 'https://api-prodooh.oohgroup.com/pdfs/temporary',
    excels_temporary: 'https://api-prodooh.oohgroup.com/excels/temporary',
    upload_files_particular_points: 'https://api-prodooh.oohgroup.com/particular-points-interest/upload',
    files_particular_points: 'https://api-prodooh.oohgroup.com/files/temporary',
    base_files: 'https://api-prodooh.oohgroup.com/files',
    kmlKmzUpload: 'https://api-prodooh.oohgroup.com/mapping-file/upload',
    kmlKmzFiles: 'https://api-prodooh.oohgroup.com/files/kml_kmz',
    uploadImpactsFiles: 'https://api-prodooh.oohgroup.com/impacts/upload',
    uploadImpactsReportFiles: 'https://api-prodooh.oohgroup.com/impacts-report/upload',
    uploudFiles: 'https://api-prodooh.oohgroup.com/file-manager/upload',
    photographicWitnesses: 'https://api-prodooh.oohgroup.com/campaigns/witnesses/upload/image',
    witnessesAdManager: 'https://api-prodooh.oohgroup.com/campaigns/witnesses/upload/reports',
    witnesses_imgs: 'https://api-prodooh.oohgroup.com/img/witnesses',
    temporary_witnesses_zip: 'https://api-prodooh.oohgroup.com/files/temporary/zips',
    download_witnesses_imgs: 'https://api-prodooh.oohgroup.com/public/download/img',
    temporary_files: 'https://api-prodooh.oohgroup.com/files/temporary',
    img_reports: 'https://api-prodooh.oohgroup.com',
    upload_report_ad_circuit: 'https://api-prodooh.oohgroup.com/campaigns/ads/report/upload',
    company_upload_img_portada: 'https://api-prodooh.oohgroup.com/companies/upload/image-portada',
    pdf_portada: 'https://api-prodooh.oohgroup.com/img/portadas',
    country_promotions: 'https://api-prodooh.oohgroup.com/countries/upload/promotions',
    pdf_promotions: 'https://api-prodooh.oohgroup.com/pdfs/promotions/',
    screen_template: 'https://api-prodooh.oohgroup.com/screen-template/upload-file'
  }

  /*
  endpoints: {
    login: 'https://pixeled.prodooh.com/oauth/token',
    services: 'https://pixeled.prodooh.com',
    users_imgs: 'https://pixeled.prodooh.com/img/users',
    screens_imgs: 'https://pixeled.prodooh.com/img/screens',
    companies_imgs: 'https://pixeled.prodooh.com/img/companies',
    user_upload_img: 'https://pixeled.prodooh.com/users/upload/image',
    screen_upload_img: 'https://pixeled.prodooh.com/screens/image/upload',
    company_upload_img: 'https://pixeled.prodooh.com/companies/upload/image',
    pdfs_temporary: 'https://pixeled.prodooh.com/pdfs/temporary',
    excels_temporary: 'https://pixeled.prodooh.com/excels/temporary',
    upload_files_particular_points: 'https://pixeled.prodooh.com/particular-points-interest/upload',
    files_particular_points: 'https://pixeled.prodooh.com/files/temporary',
    base_files: 'https://pixeled.prodooh.com/files',
    kmlKmzUpload: 'https://pixeled.prodooh.com/mapping-file/upload',
    kmlKmzFiles: 'https://pixeled.prodooh.com/files/kml_kmz',
    uploadImpactsFiles: 'https://pixeled.prodooh.com/impacts/upload',
    uploadImpactsReportFiles: 'https://pixeled.prodooh.com/impacts-report/upload',
    uploudFiles: 'https://pixeled.prodooh.com/file-manager/upload',
    photographicWitnesses: 'https://pixeled.prodooh.com/campaigns/witnesses/upload/image',
    witnessesAdManager: 'https://pixeled.prodooh.com/campaigns/witnesses/upload/reports',
    witnesses_imgs: 'https://pixeled.prodooh.com/img/witnesses',
    temporary_witnesses_zip: 'https://pixeled.prodooh.com/files/temporary/zips',
    download_witnesses_imgs: 'https://pixeled.prodooh.com/public/download/img',
    temporary_files: 'https://pixeled.prodooh.com/files/temporary',
    upload_report_ad_circuit: 'https://pixeled.prodooh.com/campaigns/ads/report/upload',
    company_upload_img_portada: 'https://pixeled.prodooh.com/companies/upload/image-portada',
    pdf_portada: 'https://pixeled.prodooh.com/img/portadas',
    country_promotions: 'https://pixeled.prodooh.com/countries/upload/promotions'
    pdf_promotions: 'https://pixeled.prodooh.com/countries/upload/promotions',
    pdf_promotions: 'https://pixeled.prodooh.com/pdfs/promotions/',
    screen_template: 'https://pixeled.prodooh.com/screen-template/upload-file'
  } */

};
